export const FirebaseConfig = {
  MemberAppConfig: {
    'projectId': 'dev-bwrm-prism',
    'appId': '1:229659363617:web:8a4a5968c6c10292',
    'databaseURL': 'https://dev-bwrm-prism.firebaseio.com',
    'storageBucket': 'dev-bwrm-prism',
    'apiKey': 'AIzaSyAoUzEEjxJnUEmgDdwRelXzWEvZ2Jds3L4',
    'authDomain': 'dev-bwrm-prism.firebaseapp.com',
    'messagingSenderId': '229659363617',
    'measurementId': 'G-XM6EY3HQPC'
  },
  memberFCMPublicVapidKey: 'BAG-obgBsSfp3AYR-K8DAx7y076Zw7MCVbiBJ36Lh2u2S16tP41ZlwdVIEGaBqhaPHdjA1JjYka0eEkmOCs5InI',
  ProcessorAppConfig: {
    'apiKey': 'AIzaSyCnMuTKft9oSEH3ghMloUCp38FzduVvZYo',
    'databaseURL': 'https://dev-bwrm-processor-prism.firebaseio.com',
    'storageBucket': 'dev-bwrm-processor-prism',
    'authDomain': 'dev-bwrm-processor-prism.firebaseapp.com',
    'messagingSenderId': '125094837764',
    'projectId': 'dev-bwrm-processor-prism'
  }
};
